import React, { useState } from "react";
import _ from "lodash";
import { nodetypes } from "./nodetypes";

export default function NodeType(props) {
  const { setNodeType, setGroup } = props;

  const [type, setType] = useState(props.type);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "70%",
      }}
    >
      Type
      {nodetypes.map((a, i) => (
        <div
          style={{
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: "solid",
            padding: 4,
            margin: 4,
            backgroundColor: type === a ? "black" : "white",
            color: type !== a ? "black" : "white",
          }}
          //onClick={() => setGroup(i)}
          onClick={() => {
            setType(a);
            setNodeType(a);
            setGroup(i);
          }}
        >
          {a}
        </div>
      ))}
    </div>
  );
}
