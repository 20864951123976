const nodetypes = [
  "container",
  "app",
  "item",
  "logic",
  "instance type",
  "event",
  "poll",
  "view",
  "field",
  "field group",
  "portlet",
  "action",
  "condition",
  "button",
];

const colors = [
  "#FF0000",
  "#00FF00",
  "#0000FF",
  "#FFFF00",
  "#00FFFF",
  "#FF00FF",
  "#FFFFFF",
  "#800000",
  "#008000",
  "#000080",
  "#808000",
  "#008080",
  "#800080",
  "#808080",
  "#C0C0C0",
  "#FFA500",
  "#FFC0CB",
  "#ADD8E6",
  "#F0FFFF",
];

export { nodetypes, colors };
