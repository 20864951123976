export default class Node {
  constructor(id, value) {
    this.value = value || { name: `${id}` };
    this.id = id;
    this.adjacents = []; // adjacency list
  }

  addAdjacent(node) {
    this.adjacents.push(node);
  }

  removeAdjacent(node) {
    let index = this.adjacents.indexOf(node);
    if (index === -1) {
      index = this.adjacents.findIndex((a) => a.node.id === node.id);
    }
    if (index > -1) {
      this.adjacents.splice(index, 1);
      return node;
    }
  }

  getAdjacents() {
    return this.adjacents;
  }

  isAdjacent(node) {
    return this.adjacents.indexOf(node) > -1;
  }

  toTree(subtree) {
    const nodes = [...(subtree?.nodes || [])];
    const links = [...(subtree?.links || [])];
    if (!nodes.filter((n) => n.id === this.id).length) nodes.push(this);
    for (let i = 0; i < this.adjacents.length; i++) {
      const a = this.adjacents[i];
      console.log("aaaaaa", a);
      if (!nodes.filter((n) => n.id === a.node.id).length) {
        nodes.push({
          ...a.node,
          name: a.node.value.name,
          label: a.node.value.name,
          id: a.node.id,
        });
      }
      links.push({ source: this.id, target: a.node.id, type: a.type });
    }
    return { nodes, links };
  }
}
