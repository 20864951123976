import logo from "./logo.svg";
import "./App.css";
import { ForceGraph2D } from "react-force-graph";
import { useRef, useCallback, useEffect, useState, useMemo } from "react";
import SpriteText from "three-spritetext";
import EdgeType from "./EdgeType";
import NodeType from "./NodeType";
import _ from "lodash";

function AddNode(props) {
  const { selectedNode, addNode, side, deleteNode, refresh } = props;

  const [leftNodeName, setLeftNodeName] = useState(selectedNode?.name);
  const [nodeName, setNodeName] = useState();
  const [nodeType, setNodeType] = useState();
  const [edgeType, setEdgeType] = useState();
  const [attributeName, setAttributeName] = useState();
  const [attributeValue, setAttributeValue] = useState();
  const [group, setGroup] = useState();

  useEffect(() => {
    add();
  }, [group, nodeType, edgeType]);

  useEffect(() => {
    if (edgeType === "type of") setNodeType("instance type");
  }, [edgeType]);

  const add = () => {
    if (group !== undefined && nodeType && edgeType && nodeName) {
      addNode(selectedNode, nodeName, group, nodeType, edgeType, side);
      setNodeName("");
      setGroup(undefined);
      setEdgeType(undefined);
    }
  };

  return (
    <>
      <div style={{ textAlign: "left" }}>
        <button onClick={deleteNode}>delete</button>
      </div>

      {_.map(selectedNode.value.attributes || {}, (v, k) => {
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span>{k}</span>
          <span>{v}</span>
        </div>;
      })}

      <div style={{ display: "flex", flexDirection: "row" }}>
        <input
          onChange={(e) => setAttributeName(e.target.value)}
          placeholder="attribute"
        ></input>
        <input
          onChange={(e) => setAttributeValue(e.target.value)}
          placeholder="value"
        ></input>
        <button
          onClick={() => {
            selectedNode.value.attributes = {
              ...(selectedNode.value.attributes || {}),
              [attributeName]: attributeValue,
            };
          }}
        >
          add
        </button>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            border: "#888888 solid 1px",
            flexDirection: "column",
            borderRadius: 5,
          }}
        >
          <input
            defaultValue={selectedNode?.name}
            onChange={(e) => setLeftNodeName(e.target.value)}
            onBlur={() => {
              selectedNode.value.name = leftNodeName;
              refresh();
            }}
          />
          <NodeType
            setNodeType={(nt) => {
              selectedNode.value.nodeType = nt;
              refresh();
            }}
            setGroup={(g) => {
              selectedNode.group = g;
              refresh();
            }}
            type={selectedNode?.value.nodeType}
          />
        </div>
        <div
          style={{
            marginTop: 15,
            width: 100,
            borderTop: "#888888 solid 1px",
            flexDirection: "column",
          }}
        >
          <EdgeType
            setClick={setEdgeType}
            edgeType={edgeType}
            name="Edge Type"
          />
        </div>
        <div
          style={{ border: "#888888 solid 1px", padding: 4, borderRadius: 5 }}
        >
          <input
            placeholder="new node"
            defaultValue={nodeName}
            onChange={(e) => setNodeName(e.target.value)}
          />
          <NodeType setNodeType={setNodeType} setGroup={setGroup} />
        </div>
      </div>
      <div>
        {selectedNode.id}: {JSON.stringify(selectedNode.value)}
      </div>

      <button onClick={add}>Add</button>
    </>
  );
}

export default AddNode;
