import React, { useState } from "react";
import _ from "lodash";

export default function EdgeType(props) {
  const { setClick, name } = props;

  const [edgeType, setEdgeType] = useState();

  return (
    <div style={{ height: 200, overflow: "auto" }}>
      {name !== undefined && name}
      {[
        "contains list of",
        "type of",
        "has logic",
        "results in",
        "related to current",
        "has view",
        "has attribute",
        "triggers",
        "meta fields",
        "has",
        "relavent to",
      ].map((a, i) => (
        <div
          style={{
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: "solid",
            padding: 4,
            margin: 4,
            backgroundColor: edgeType === a ? "black" : "white",
            color: edgeType !== a ? "black" : "white",
          }}
          onClick={() => {
            setClick(a);
            setEdgeType(a);
          }}
        >
          {a}
        </div>
      ))}
    </div>
  );
}
