import logo from "./logo.svg";
import "./App.css";
import { ForceGraph3D, ForceGraphMethods } from "react-force-graph";
import { useRef, useCallback, useEffect, useState, forwardRef } from "react";
import SpriteText from "three-spritetext";
import AddNode from "./AddNode";
import * as THREE from "three";
import { TextGeometry } from "three/addons/geometries/TextGeometry.js";
import { FontLoader } from "three/addons/loaders/FontLoader.js";
import { nodetypes, colors } from "./nodetypes";

import { ReactSearchAutocomplete } from "react-search-autocomplete";

export default forwardRef(function GraphComp(props, graph) {
  const { ptree, setNode, addNode, refresh, setEdge, side } = props;
  const fgRef = useRef();
  const font = useRef();
  const currentSubTree = useRef();
  const subTrees = useRef({});

  const [tree, setTree] = useState();
  const [foundNode, setFoundNode] = useState();
  const [subTree, setSubTree] = useState();
  const [nodeName, setNodeName] = useState("");
  const [selectedNode, setSelectedNode] = useState();

  useEffect(() => {
    setTree(data({ nodes: [...ptree.nodes], links: [...ptree.links] }));
  }, [ptree]);

  useEffect(() => {
    const loader = new FontLoader();
    loader.load(
      "https://threejs.org/examples/fonts/helvetiker_regular.typeface.json",
      function (f) {
        font.current = f;
      }
    );
  }, []);

  useEffect(() => {
    setNode(selectedNode);
  }, [selectedNode]);

  const handleClick = useCallback(
    (node) => {
      // Aim at node from outside it
      //const distance = 120;
      //const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

      // fgRef.current.cameraPosition(
      //   { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
      //   node, // lookAt ({ x, y, z })
      //   3000 // ms transition duration
      // );
      console.log("newsubtree  subtree", subTree);
      if (currentSubTree.current && graph.current && graph.current.nodes) {
        if (subTrees.current[node.id]) {
          setSelectedNode(node);
        } else {
          subTrees.current[node.id] = true;
          const n = graph.current.nodes.get(node.id);
          const newsubtree = n.toTree(currentSubTree.current);
          console.log("newsubtree", newsubtree);
          setSubTree(newsubtree);
          currentSubTree.current = newsubtree;
          const distance = 120;
          const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

          fgRef.current.cameraPosition(
            {
              x: node.x * distRatio,
              y: node.y * distRatio,
              z: node.z * distRatio,
            }, // new position
            node, // lookAt ({ x, y, z })
            3000 // ms transition duration
          );
        }
      } else {
        console.log("node", node);
        setSelectedNode(node);
      }
    },
    [fgRef]
  );
  const handleRightClick = useCallback(
    (node) => {
      // Aim at node from outside it
      const distance = 120;
      const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

      fgRef.current.cameraPosition(
        { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
        node, // lookAt ({ x, y, z })
        3000 // ms transition duration
      );
    },
    [fgRef]
  );
  const handleLinkClick = useCallback(
    (link) => {
      console.log("link", link);
      // Aim at node from outside it
      setEdge(link);
    },
    [fgRef]
  );

  const data = (t) => {
    const gData = t;

    // cross-link node objects
    gData.links?.forEach((link) => {
      const a = gData.nodes[link.source];
      const b = gData.nodes[link.target];
      if (!a || !b) return;
      !a.neighbors && (a.neighbors = []);
      !b.neighbors && (b.neighbors = []);
      a.neighbors.push(b);
      b.neighbors.push(a);

      !a.links && (a.links = []);
      !b.links && (b.links = []);
      a.links.push(link);
      b.links.push(link);
    });

    return gData;
  };

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);

  const NODE_R = 8;

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleNodeHover = (node) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node && node.neighbors) {
      highlightNodes.add(node);
      node?.neighbors?.forEach((neighbor) => highlightNodes.add(neighbor));
      node.links?.forEach((link) => highlightLinks.add(link));
    }

    setHoverNode(node || null);
    updateHighlight();
  };

  const handleLinkHover = (link) => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (link) {
      highlightLinks.add(link);
      highlightNodes.add(link.source);
      highlightNodes.add(link.target);
    }

    updateHighlight();
  };

  const paintRing = useCallback(
    (node, ctx) => {
      console.log("paintRing", node);
      // add ring just for highlighted nodes
      ctx.beginPath();
      ctx.arc(node.x, node.y, 180 * 1.4, 0, 2 * Math.PI, false);
      ctx.fillStyle = node === hoverNode ? "red" : "orange";
      ctx.fill();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = node.color;
      ctx.fillText(node.id, node.x, node.y);
    },
    [hoverNode]
  );

  function Item({ children, color }) {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ background: color, width: "15px", height: "15px" }} />
        <div style={{ color: "white", fontSize: "11px" }}>{children}</div>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: 75,
          left: "20",
          gap: "1",
          zIndex: 999,
        }}
      >
        {nodetypes.map((nodetype, i) => (
          <Item key={colors[i]} color={colors[i]}>
            {nodetype}
          </Item>
        ))}
      </div>
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 99999,
          width: 200,
        }}
      >
        {tree && (
          <ReactSearchAutocomplete
            items={tree.nodes}
            onSearch={(string, results) => {
              console.log(string, results);
            }}
            onHover={(result) => {
              console.log(result);
            }}
            onSelect={(item) => {
              console.log("fgRef.current", fgRef.current);
              const node = fgRef.current.getGraphBbox((n) => n.id === item.id);
              setFoundNode(item);
              const n = graph.current.nodes.get(item.id);
              const subtree = n.toTree();
              console.log("subtree", subtree);
              setSubTree(subtree);
              currentSubTree.current = subtree;
              console.log("node", node);
              if (node) {
                const distance = 120;
                const distRatio =
                  1 + distance / Math.hypot(node.x[1], node.y[1], node.z[1]);
                fgRef.current.cameraPosition(
                  {
                    x: node.x[1] * distRatio,
                    y: node.y[1] * distRatio,
                    z: node.z[1] * distRatio,
                  }, // new position
                  node, // lookAt ({ x, y, z })
                  3000 // ms transition duration
                );
                //setSelectedNode(node);
              }
            }}
            onFocus={() => {
              console.log("Focused");
            }}
            autoFocus
            formatResult={(item) => {
              return (
                <>
                  <span style={{ display: "block", textAlign: "left" }}>
                    {item.name}
                  </span>
                </>
              );
            }}
          />
        )}
      </div>
      {selectedNode !== undefined && (
        <div
          style={{
            fontSize: 8,
            position: "absolute",
            top: 40,
            right: 0,
            backgroundColor: "white",
            zIndex: 999,
            padding: 8,
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            opacity: 0.7,
          }}
        >
          <div style={{ position: "relative" }}>
            <div
              onClick={() => setSelectedNode(undefined)}
              style={{ position: "absolute", top: 0, right: 0 }}
            >
              s✖️
            </div>
            <AddNode
              addNode={addNode}
              refresh={refresh}
              selectedNode={selectedNode}
              side={side}
            />
          </div>
        </div>
      )}
      {tree !== undefined && (
        <ForceGraph3D
          ref={fgRef}
          nodeLabel="label"
          width={props.width}
          height={props.height}
          //nodeAutoColorBy="group"
          nodeColor={(node) => {
            try {
              return colors[node.group];
            } catch (ex) {
              return "red";
            }
          }}
          onLinkClick={handleLinkClick}
          graphData={subTree || tree}
          linkThreeObjectExtend={true}
          linkThreeObject={(link) => {
            // extend link with text sprite
            const sprite = new SpriteText(`${link.type}`);
            sprite.color = "lightgrey";
            sprite.textHeight = 1.5;
            return sprite;
          }}
          linkPositionUpdate={(sprite, { start, end }) => {
            const middlePos = Object.assign(
              ...["x", "y", "z"].map((c) => ({
                [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
              }))
            );

            // Position sprite
            Object.assign(sprite.position, middlePos);
          }}
          onNodeClick={handleClick}
          onNodeRightClick={handleRightClick}
          onLinkClick={handleLinkClick}
          // nodeCanvasObjectMode={(node) => {
          //   console.log("nodeCanvasObjectMode");

          //   return node.id === foundNode?.id ? "after" : undefined;
          // }}
          nodeThreeObject={({ id }) => {
            if (id === foundNode?.id) {
              // console.log(
              //   "new TextGeometry(foundNode.name),",
              //   new TextGeometry(foundNode.name)
              // );
              return new THREE.Mesh(
                new THREE.DodecahedronGeometry(15),
                // new TextGeometry(foundNode.name, {
                //   font: font.current,
                //   size: 12,
                //   color: "black",
                // }),
                new THREE.MeshLambertMaterial({
                  color: "white",
                  transparent: true,
                  opacity: 0.75,
                })
              );
            }
            // return new THREE.Mesh(
            //   [
            //     new THREE.BoxGeometry(
            //       Math.random() * 20,
            //       Math.random() * 20,
            //       Math.random() * 20
            //     ),
            //     new THREE.ConeGeometry(
            //       Math.random() * 10,
            //       Math.random() * 20
            //     ),
            //     new THREE.CylinderGeometry(
            //       Math.random() * 10,
            //       Math.random() * 10,
            //       Math.random() * 20
            //     ),
            //     new THREE.DodecahedronGeometry(Math.random() * 10),
            //     new THREE.SphereGeometry(Math.random() * 10),
            //     new THREE.TorusGeometry(
            //       Math.random() * 10,
            //       Math.random() * 2
            //     ),
            //     new THREE.TorusKnotGeometry(
            //       Math.random() * 10,
            //       Math.random() * 2
            //     ),
            //   ][id % 7],
            //   new THREE.MeshLambertMaterial({
            //     color: Math.round(Math.random() * Math.pow(2, 24)),
            //     transparent: true,
            //     opacity: 0.75,
            //   })
            // );
          }}
          nodeRelSize={NODE_R}
          autoPauseRedraw={false}
          linkWidth={(link) => (highlightLinks.has(link) ? 5 : 1)}
          linkDirectionalParticles={4}
          linkDirectionalParticleWidth={(link) =>
            highlightLinks.has(link) ? 4 : 0
          }
          // nodeCanvasObjectMode={(node) =>
          //   highlightNodes.has(node) || node.id === foundNode.id
          //     ? "before"
          //     : undefined
          // }
          nodeCanvasObject={paintRing}
          onNodeHover={handleNodeHover}
          onLinkHover={handleLinkHover}
          // nodeThreeObject={({ id }) =>
          //   new THREE.Mesh(
          //     // [
          //     //   new THREE.BoxGeometry(
          //     //     Math.random() * 20,
          //     //     Math.random() * 20,
          //     //     Math.random() * 20
          //     //   ),
          //     //   new THREE.ConeGeometry(Math.random() * 10, Math.random() * 20),
          //     //   new THREE.CylinderGeometry(
          //     //     Math.random() * 10,
          //     //     Math.random() * 10,
          //     //     Math.random() * 20
          //     //   ),
          //     //   new THREE.DodecahedronGeometry(Math.random() * 10),
          //     //   new THREE.SphereGeometry(Math.random() * 10),
          //     //   new THREE.TorusGeometry(Math.random() * 10, Math.random() * 2),
          //     //   new THREE.TorusKnotGeometry(
          //     //     Math.random() * 10,
          //     //     Math.random() * 2
          //     //   ),
          //     // ][id % 7],
          //     // new THREE.MeshLambertMaterial({
          //     //   color: Math.round(Math.random() * Math.pow(2, 24)),
          //     //   transparent: true,
          //     //   opacity: 0.75,
          //     // }),
          //     new TextGeometry("asdf", {
          //       font: font.current,
          //       size: 80,
          //       height: 35,
          //       curveSegments: 12,
          //       bevelEnabled: true,
          //       bevelThickness: 10,
          //       bevelSize: 8,
          //       bevelOffset: 0,
          //       bevelSegments: 5,
          //     })
          //   )
          // }
        />
      )}
    </div>
  );
});
