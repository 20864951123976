import logo from "./logo.svg";
import "./App.css";
import { ForceGraph3D } from "react-force-graph";
import { useRef, useCallback, useEffect, useState, useMemo } from "react";
import SpriteText from "three-spritetext";
import Graph from "./Graph";
import GraphComp from "./GraphComp";
import GraphComp3D from "./GraphComp3D";
import GraphCompAR from "./GraphCompAR";
import EdgeType from "./EdgeType";
import RestHelper from "./helpers/RestHelper";

function Architect() {
  const fgRef = useRef();
  const graph = useRef();

  const [leftNode, setLeftNode] = useState();
  const [rightNode, setRightNode] = useState();
  const [planName, setPlanName] = useState("unsaved");
  const [edge, setEdge] = useState();
  const [plans, setPlans] = useState({});
  const [tree, setTree] = useState();
  const [edgeType, setEdgeType] = useState(" ");

  useEffect(() => {
    if (!graph.current) {
      genRandomTree();
    }
  }, []);

  useEffect(() => {
    if (tree && graph.current) savePlan();
  }, [tree]);

  const genRandomTree = (N = 2, reverse = false) => {
    RestHelper.fetchAuthenticated({
      url: "/generic/getNodes",
      body: {
        nodeType: "Plan",
        // title: "draft2",
      },
      cb: (response) => {
        console.log("response", response);
        const p = {};
        let b, t;
        for (let i = 0; i < response.nodes.length; i++) {
          const n = response.nodes[i];
          b = JSON.parse(n.body);
          t = n.title;
          p[t] = b;
        }
        setPlans(p);
        setPlanName(t);
        loadGraph(b);
      },
    });
  };

  function genRandomTreeOld(N = 2, reverse = false) {
    const data = localStorage.getItem("plans");
    if (data) {
      const json = JSON.parse(data);
      setPlans(json);
      if (Object.keys(json).length) {
        const planName = Object.keys(json)[0];
        setPlanName(planName);
        loadGraph(json[planName]);
      }
    } else {
      const g = new Graph(Graph.DIRECTED);
      const tree = {
        nodes: [...Array(N).keys()].map((i) => ({ id: i })),
        links: [...Array(N).keys()]
          .filter((id) => id)
          .map((id) => ({
            [reverse ? "target" : "source"]: id,
            [reverse ? "source" : "target"]: Math.round(
              Math.random() * (id - 1)
            ),
          })),
      };

      for (let i = 0; i < tree.links.length; i++) {
        const l = tree.links[i];
        g.addEdge(l.source, l.target);
      }
      graph.current = g;
      const t = g.toTree();
      setPlans({ [planName]: tree });
      setTree(t);
    }

    // tree.links.forEach((l) => {
    //   // const source = graph.addVertex();
    //   // const target = graph.addVertex(l.target);
    //   graph.addEdge(l.source, l.target);
    // });
    return graph;
  }

  const loadGraph = (json) => {
    const graphh = new Graph(Graph.DIRECTED);
    for (let i = 0; i < json.nodes.length; i++) {
      const n = json.nodes[i];
      const nn = graphh.addVertex(n.id);
      nn.value = n.value;
      nn.group = n.group;
    }
    for (let i = 0; i < json.nodes.length; i++) {
      const n = json.nodes[i];
      for (let j = 0; j < n.adjacents.length; j++) {
        const a = n.adjacents[j];
        graphh.addEdge(n.id, a.node.id, a.type);
      }
    }

    graph.current = graphh;
    const t = graph.current.toTree();
    setTree(t);
  };

  const addNode = (selectedNode, nodeName, group, nodeType, edgeType, side) => {
    const edge = graph.current.addEdge(
      selectedNode.id,
      graph.current.nodes.size,
      edgeType
    );
    const dest = edge[1];
    dest.value = { name: nodeName, nodeType };
    dest.group = group;
    setTree(graph.current.toTree());
    if (side === "left") {
      setLeftNode(dest);
    } else {
      setRightNode(dest);
    }
  };

  const refresh = () => {
    const t = graph.current.toTree();
    setTree(t);
  };

  const deleteNode = (node) => {
    graph.current.removeVertex(node.id);
    setTree(graph.current.toTree());
  };

  const addEdge = (edgeType) => {
    const edge = graph.current.addEdge(leftNode.id, rightNode.id, edgeType);
    setTree(graph.current.toTree());
  };
  const deleteEdge = () => {
    graph.current.removeEdge(edge.source.id, edge.target.id);
    console.log(graph.current.nodes);
    setTree(graph.current.toTree());
  };

  const savePlan = () => {
    const newPlans = { ...plans };
    newPlans[planName] = graph.current.toJson();
    localStorage.setItem("plans", JSON.stringify(newPlans));
    setPlans(newPlans);
    RestHelper.fetchAuthenticated({
      url: "/generic/editNode",
      body: {
        title: planName,
        nodeType: "Plan",
        propertyName: "body",
        propertyValue: JSON.stringify(graph.current.toJson()),
      },
      cb: () => {},
    });
  };

  return (
    <div className="App" style={{}}>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 9999,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            key={`planName${planName}`}
            defaultValue={planName}
            onChange={(e) => setPlanName(e.target.value)}
          />
          <button onClick={savePlan}>save</button>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <select
            onChange={(e) => {
              const planName = Object.keys(plans)[e.target.selectedIndex];
              setPlanName(planName);
              loadGraph(plans[planName]);
            }}
          >
            {Object.keys(plans).map((p, i) => (
              <option>{p}</option>
            ))}
          </select>
          <button onClick={() => {}}>delete</button>
          <button
            onClick={() => {
              setPlanName("");
              loadGraph({
                nodes: [
                  {
                    value: { name: "PatternAI", nodeType: "app" },
                    id: 0,
                    adjacents: [],
                    group: 0,
                  },
                ],
              });
            }}
          >
            new
          </button>
        </div>
      </div>
      {edge !== undefined && (
        <div
          style={{
            position: "absolute",
            top: 0,
            backgroundColor: "white",
            border: "solid 1px black",
            zIndex: 9999,
            left: window.innerWidth / 2 - 50,
          }}
        >
          Edge
          <div style={{ display: "flex", flexDirection: "row" }}>
            {edge.source.name}-<button onClick={deleteEdge}>delete</button>-
            {edge.target.name}
          </div>
        </div>
      )}
      {leftNode !== undefined && rightNode !== undefined && (
        <div
          style={{
            position: "absolute",
            top: 50,
            backgroundColor: "white",
            border: "solid 1px black",
            zIndex: 9999,
            left: window.innerWidth / 2 - 50,
          }}
        >
          Edge Type
          <EdgeType setClick={addEdge} />
          {/* <button onClick={addEdge}>Add Edge</button> */}
        </div>
      )}
      {/* {tree !== undefined && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: window.innerHeight * 0.5,
          }}
        >
          <div
            style={{
              flex: 1,
              position: "relative",
              backgroundColor: "black",
              color: "white",
            }}
          >
            <iframe
              width={window.innerWidth * 0.5}
              height={window.innerHeight * 0.5}
              src="/app3"
            />
          </div>
          <div style={{ flex: 1, position: "relative" }}>
            <iframe
              width={window.innerWidth * 0.5}
              height={window.innerHeight * 0.5}
              src="/app2"
            />
          </div>
        </div>
      )} */}
      {window.innerWidth > 768 ? (
        <>
          {tree !== undefined && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div style={{ flex: 1, position: "relative" }}>
                Data
                <GraphComp
                  key="efff"
                  ptree={tree}
                  refresh={refresh}
                  deleteNode={() => deleteNode(leftNode)}
                  setNode={setLeftNode}
                  addNode={addNode}
                  side="left"
                  setEdge={setEdge}
                  width={window.innerWidth * 0.5}
                  height={window.innerHeight * 0.5}
                />
              </div>
              <div
                style={{
                  flex: 1,
                  position: "relative",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                overview
                <GraphComp3D
                  key="wer"
                  ref={graph}
                  ptree={tree}
                  deleteNode={() => deleteNode(rightNode)}
                  addNode={addNode}
                  side="right"
                  refresh={refresh}
                  setNode={setRightNode}
                  setEdge={setEdge}
                  width={window.innerWidth * 0.5}
                  height={window.innerHeight * 0.5}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        tree !== undefined && (
          <GraphComp3D
            key="wer"
            ref={graph}
            ptree={tree}
            deleteNode={() => deleteNode(rightNode)}
            addNode={addNode}
            side="right"
            refresh={refresh}
            setNode={setRightNode}
            setEdge={setEdge}
          />
        )
      )}
    </div>
  );
}

export default Architect;
