import logo from "./logo.svg";
import "./App.css";
import { ForceGraph3D } from "react-force-graph";
import { useRef, useCallback, useEffect, useState, useMemo } from "react";
import SpriteText from "three-spritetext";
import Graph from "./Graph";
import GraphComp from "./GraphComp";
import GraphComp3D from "./GraphComp3D";
import GraphCompAR from "./GraphCompAR";
import EdgeType from "./EdgeType";

function App() {
  const fgRef = useRef();
  const graph = useRef();

  const [leftNode, setLeftNode] = useState();
  const [rightNode, setRightNode] = useState();
  const [edge, setEdge] = useState();
  const [tree, setTree] = useState();
  const [edgeType, setEdgeType] = useState("");

  useEffect(() => {
    //graph.current = genRandomTree();
    //setTree(graph.current.toTree());
  }, []);

  const addNode = (selectedNode, nodeName, group, type) => {
    const edge = graph.current.addEdge(
      selectedNode.id,
      graph.current.nodes.size
    );
    const dest = edge[1];
    dest.value = { name: nodeName, type };
    dest.group = group;
    setTree(graph.current.toTree());
  };
  const deleteNode = (node) => {
    graph.current.removeVertex(node.id);
    setTree(graph.current.toTree());
  };

  const addEdge = (edgeType) => {
    const edge = graph.current.addEdge(leftNode.id, rightNode.id, edgeType);
    setTree(graph.current.toTree());
  };
  const deleteEdge = () => {
    graph.current.removeEdge(edge.source.id, edge.target.id);
    console.log(graph.current.nodes);
    setTree(graph.current.toTree());
  };

  return (
    <div>
      {tree !== undefined && (
        <div style={{ flex: 1, position: "relative" }}>
          <GraphComp
            key="efff"
            ptree={tree}
            deleteNode={() => deleteNode(leftNode)}
            setNode={setLeftNode}
            addNode={addNode}
            setEdge={setEdge}
            width={window.innerWidth}
            height={window.innerHeight}
          />
        </div>
      )}
    </div>
  );
}

export default App;
