import React from "react";

import RestHelper from "./helpers/RestHelper";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  login() {
    const { t } = this.props;
    RestHelper.fetch({
      url: "/users/login",
      body: this.state,
      cb: (result) => {
        if (result.success) {
          localStorage.setItem("userToken", result.token);
          localStorage.setItem("username", result.username);
          this.props.getUser();
        } else {
          alert("invalidLogin");
        }
      },
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: window.innerHeight,
        }}
      >
        <div
          style={{
            height: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <span>username</span>
          <input
            onChange={(e) => this.setState({ username: e.target.value })}
            style={{ height: 25 }}
          />
          <span>password</span>
          <input
            type="password"
            onChange={(e) => this.setState({ password: e.target.value })}
            style={{ height: 25 }}
          />
          <button
            style={{ height: 25 }}
            onClick={() => {
              this.login();
            }}
          >
            Login
          </button>
          <div
            style={{
              borderBottomColor: "#dddddd",
              borderBottomWidth: 1,
              padding: 20,
            }}
          />
        </div>
      </div>
    );
  }
}
