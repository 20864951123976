let urlPrefix = "http://127.0.0.1:3001";
//let urlPrefix = "http://10.209.150.174:3001";
let defaultUrlPrefix = "https://ancient-garden-51779.herokuapp.com";
//defaultUrlPrefix = "http://localhost:3001";
//let urlPrefix = "http://ad7840cad754.ngrok.io";
const urlPrefix1 = defaultUrlPrefix;
class RestHelper {
  //urlPrefix = "http://10.0.2.2:3001";

  //urlPrefix = "http://192.168.1.52:3001";

  wssUrl = "ws://ancient-garden-51779.herokuapp.com/";
  //wssUrl = "ws://127.0.0.1:3001/";

  //urlPrefix = "https://ancient-garden-51779.herokuapp.com";
  //urlPrefix = "http://localhost:3001";
  urlPrefix = defaultUrlPrefix;

  getUrlPrefix() {}

  fetch(data) {
    //console.log("urlPrefix", this.urlPrefix);
    let url = this.urlPrefix + data.url;
    const language = "en";
    //console.log('language', language);
    try {
      if (!data.body.language) data.body.language = language;
      if (!data.body.appName) data.body.appName = "com.doe.metwork";
    } catch (ex) {}

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.body),
    })
      .then((res) => res.json())
      .then((result) => {
        data.cb(result);
      })
      .catch((error) => {
        urlPrefix = urlPrefix1;
      });
  }

  fetchAuthenticated(data, json = true) {
    const token = localStorage.getItem("userToken");
    let url = this.urlPrefix + data.url;

    if (!data.body.appName) data.body.appName = "com.doe.metwork";

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data.body),
    })
      .then((res) => res.json())
      .then((result) => {
        data.cb(result);
      });
  }
}

export default new RestHelper();
