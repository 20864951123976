import { useRef, useCallback, useEffect, useState, useMemo } from "react";
import RestHelper from "./helpers/RestHelper";
import { JSONTree } from "react-json-tree";
import _ from "lodash";

function Emails() {
  useEffect(() => {
    RestHelper.fetchAuthenticated({
      url: "/generic/getNodes",
      body: { nodeType: "EmailHtml", owner: true },
      cb: (result) => {
        setEmails(result.nodes);
      },
    });
    // graph.current = genRandomTree();
    // setTree(graph.current.toTree());
  }, []);

  const [emails, setEmails] = useState({});
  const [email, setEmail] = useState({});

  return (
    <div>
      {/* <JSONTree data={emails} /> */}
      {_.map(emails, (email) => (
        <div onClick={() => setEmail(email)}>{email.subject}</div>
      ))}
      <div
        className="post_text"
        dangerouslySetInnerHTML={{ __html: email?.html || "" }}
      />
    </div>
  );
}

export default Emails;
