import Node from "./Node";

class Graph {
  constructor(edgeDirection = Graph.DIRECTED) {
    this.nodes = new Map();
    this.edgeDirection = edgeDirection;
  }

  addEdge(sourceId, destinationId, type) {
    const sourceNode = this.addVertex(sourceId);
    const destinationNode = this.addVertex(destinationId);

    sourceNode.addAdjacent({ node: destinationNode, type });

    if (this.edgeDirection === Graph.UNDIRECTED) {
      destinationNode.addAdjacent(sourceNode);
    }

    return [sourceNode, destinationNode];
  }

  addVertex(id) {
    if (this.nodes.has(id)) {
      return this.nodes.get(id);
    } else {
      const vertex = new Node(id);
      this.nodes.set(id, vertex);
      return vertex;
    }
  }

  removeVertex(id) {
    const current = this.nodes.get(id);
    if (current) {
      for (const node of this.nodes.values()) {
        node.removeAdjacent(current);
      }
    }
    return this.nodes.delete(id);
  }

  removeEdge(sourceId, destinationId) {
    const sourceNode = this.nodes.get(sourceId);
    const destinationNode = this.nodes.get(destinationId);

    if (sourceNode && destinationNode) {
      sourceNode.removeAdjacent(destinationNode);

      if (this.edgeDirection === Graph.UNDIRECTED) {
        destinationNode.removeAdjacent(sourceNode);
      }
    }

    return [sourceNode, destinationNode];
  }

  toJson() {
    const json = { nodes: [] };
    this.nodes.forEach((n, k, m) => {
      const jn = { ...n, adjacents: [] };
      n.getAdjacents().map((a) => {
        jn.adjacents.push({ ...a, adjacents: undefined });
      });
      json.nodes.push(jn);
    });
    console.log("json", json);
    console.log(JSON.stringify(json));
    return json;
  }

  toTree() {
    const nodes = [];
    let links = [];
    const json = { nodes: [] };
    this.nodes.forEach((n, k, m) => {
      const jn = { ...n, adjacents: [] };
      nodes.push({
        ...n,
        label: `${n.id} ${n.value?.name} (${n.value?.type})`,
        name: n.value.name,
      });
      links = links.concat(
        n.getAdjacents().map((a) => {
          jn.adjacents.push({ ...a });
          return { source: n.id, target: a.node.id, type: a.type };
        })
      );
      json.nodes.push(jn);
    });
    return {
      nodes,
      links,
    };
  }
}

Graph.UNDIRECTED = Symbol("directed graph"); // one-way edges
Graph.DIRECTED = Symbol("undirected graph"); // two-ways edges

export default Graph;
