import logo from "./logo.svg";
import "./App.css";
import { ForceGraph2D } from "react-force-graph";
import { useRef, useCallback, useEffect, useState, useMemo } from "react";
import SpriteText from "three-spritetext";
import AddNode from "./AddNode";

function GraphComp(props) {
  const { ptree, setNode, addNode, deleteNode, setEdge, side, refresh } = props;
  const fgRef = useRef();

  const [tree, setTree] = useState();
  const [nodeName, setNodeName] = useState("");
  const [selectedNode, setSelectedNode] = useState();

  useEffect(() => {
    setTree(data({ nodes: [...ptree.nodes], links: [...ptree.links] }));
  }, [ptree]);

  useEffect(() => {
    setNode(selectedNode);
  }, [selectedNode]);

  const handleClick = useCallback(
    (node) => {
      // Aim at node from outside it
      const distance = 40;
      const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

      // fgRef.current.cameraPosition(
      //   { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
      //   node, // lookAt ({ x, y, z })
      //   3000 // ms transition duration
      // );
      setSelectedNode(node);
    },
    [fgRef]
  );

  const handleLinkClick = useCallback(
    (link) => {
      // Aim at node from outside it
      setEdge(link);
    },
    [fgRef]
  );

  const data = (t) => {
    const gData = t;

    // cross-link node objects
    gData.links?.forEach((link) => {
      const a = gData.nodes[link.source];
      const b = gData.nodes[link.target];
      if (!a || !b) return;
      !a.neighbors && (a.neighbors = []);
      !b.neighbors && (b.neighbors = []);
      a.neighbors.push(b);
      b.neighbors.push(a);

      !a.links && (a.links = []);
      !b.links && (b.links = []);
      a.links.push(link);
      b.links.push(link);
    });

    return gData;
  };

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);

  const NODE_R = 8;

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleNodeHover = (node) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      node.neighbors?.forEach((neighbor) => highlightNodes.add(neighbor));
      node.links?.forEach((link) => highlightLinks.add(link));
    }

    setHoverNode(node || null);
    updateHighlight();
  };

  const handleLinkHover = (link) => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (link) {
      highlightLinks.add(link);
      highlightNodes.add(link.source);
      highlightNodes.add(link.target);
    }

    updateHighlight();
  };

  const paintRing = useCallback(
    (node, ctx) => {
      // add ring just for highlighted nodes
      ctx.beginPath();
      ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);
      ctx.fillStyle = node === hoverNode ? "red" : "orange";
      ctx.fill();
    },
    [hoverNode]
  );

  return (
    <div>
      {selectedNode !== undefined && (
        <div
          style={{
            position: "absolute",
            fontSize: 8,
            top: 0,
            left: 0,
            backgroundColor: "black",
            zIndex: 999,
            padding: 8,
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            opacity: 0.7,
          }}
        >
          <div style={{ position: "relative" }}>
            <div
              onClick={() => setSelectedNode(undefined)}
              style={{ position: "absolute", top: 0, right: 0 }}
            >
              ✖️
            </div>
            <AddNode
              refresh={refresh}
              selectedNode={selectedNode}
              deleteNode={deleteNode}
              addNode={addNode}
              side={side}
            />
          </div>
        </div>
      )}
      {tree !== undefined && (
        <ForceGraph2D
          ref={fgRef}
          width={props.width}
          height={props.height}
          nodeLabel="name"
          nodeAutoColorBy="group"
          graphData={tree}
          onNodeClick={handleClick}
          onLinkClick={handleLinkClick}
          nodeCanvasObject={(node, ctx, globalScale) => {
            const label = node.name;
            const fontSize = 20 / globalScale;
            ctx.font = `${fontSize}px Sans-Serif`;
            const textWidth = ctx.measureText(label).width;
            const bckgDimensions = [textWidth, fontSize].map(
              (n) => n + fontSize * 0.2
            ); // some padding

            ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
            ctx.fillRect(
              node.x - bckgDimensions[0] / 2,
              node.y - bckgDimensions[1] / 2,
              ...bckgDimensions
            );

            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillStyle = node.color;
            ctx.fillText(label, node.x, node.y);

            node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
          }}
          nodePointerAreaPaint={(node, color, ctx) => {
            ctx.fillStyle = color;
            const bckgDimensions = node.__bckgDimensions;
            bckgDimensions &&
              ctx.fillRect(
                node.x - bckgDimensions[0] / 2,
                node.y - bckgDimensions[1] / 2,
                ...bckgDimensions
              );
          }}
          // nodeCanvasObject={(node, ctx, globalScale) => {
          //   const label = node.id;
          //   const fontSize = 12 / globalScale;
          //   ctx.font = `${fontSize}px Sans-Serif`;
          //   const textWidth = ctx.measureText(label).width;
          //   const bckgDimensions = [textWidth, fontSize].map(
          //     (n) => n + fontSize * 0.2
          //   ); // some padding

          //   ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
          //   ctx.fillRect(
          //     node.x - bckgDimensions[0] / 2,
          //     node.y - bckgDimensions[1] / 2,
          //     ...bckgDimensions
          //   );

          //   ctx.textAlign = "center";
          //   ctx.textBaseline = "middle";
          //   ctx.fillStyle = node.color;
          //   ctx.fillText(label, node.x, node.y);

          //   node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
          // }}
          // nodePointerAreaPaint={(node, color, ctx) => {
          //   ctx.fillStyle = color;
          //   const bckgDimensions = node.__bckgDimensions;
          //   bckgDimensions &&
          //     ctx.fillRect(
          //       node.x - bckgDimensions[0] / 2,
          //       node.y - bckgDimensions[1] / 2,
          //       ...bckgDimensions
          //     );
          // }}
        />
      )}
    </div>
  );
}

export default GraphComp;
